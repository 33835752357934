<template>
  <li class='record-archive-single'>
    <router-link :to='{ name: `inventory/record-details`, params: { id: record.id } }' class='record-link' :aria-current='aria_current'>
      <div class='company-name font-weight-bold'>
        <span v-if="record.vendor">
          {{ record.vendor }}
        </span>
      </div>
      <div class="small">
        <span v-if="record.invoice_number">#{{ record.invoice_number }}</span>
        <span v-if="record.date && record.invoice_number"> - </span>
        <span v-if="record.date">{{ record.date }}</span>
      </div>
    </router-link>
  </li>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers( 'inventory' );

export default {
  name: 'InventoryRecordSidebarSingle',
  props: {
    record: {
      type: Object,
      required: true,
    }
  },
  computed: {

    aria_current () {
      const active_id = this.$route.params.id ?? null;
      return ( active_id && ( active_id == this.record.id ) ) ? 'page' : null;
    },

  },
}
</script>


<style lang="scss">
.record-archive-single {
  .record-link {
    border-radius: 0.5rem;
    background-color: var( --white );
    border: 1px solid var( --bs-border-color );
    color: var( --dark );
    text-align: left;
    display: block;
    padding: 1rem;
    transition-duration: var( --transition-duration );
    transition-timing-function: var( --transition-timing-function );
    transition-property: color, background-color, border-color;
    &:hover,
    &:focus,
    &[aria-current=page] {
      text-decoration: none;
      background-color: var( --blue );
      border-color: var( --blue );
      &,
      .status-label {
        color: white;
      }
    }
  }
  .order-title-row {
    align-items: baseline;
  }
  h2 {
    font-size: 1em;
  }
  .date {
    font-size: 12px;
  }
  .status-label {
    // color vars added in main.scss for simplicity of scss loop
    color: var( --status-color, var( --body-color, black ) );
    transition: var( --transition-timing );
  }
}
</style>