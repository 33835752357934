<template>
  <div>
    <b-card title="Card Title" no-body>
      <b-card-header header-tag="nav">
        <b-nav card-header pills>
          <b-nav-item :to="{ name: 'inventory' }" :active="this.$route.path.toLowerCase() == '/inventory/'">Inventory</b-nav-item>
          <b-nav-item :to="{ name: 'inventory/records' }" :active="this.$route.name == 'inventory/records' || this.$route.name == 'inventory/record-details'">Records</b-nav-item>
        </b-nav>
      </b-card-header>
    </b-card>

    <router-view/>   

  </div>
</template>

<script>
import InventoryList from './list/list';
import InventoryRecords from './records/records';

export default {
  name: 'InventoryArchive',
  components: {
    InventoryList,
    InventoryRecords,
  },
  data() {
    return {
      
    }
  },
  computed: {
    
  },
  created () {
    
  },
  methods: {

  }
}
</script>


<style scoped lang='scss'>
.tab-height {
  height: calc(100% - 63px);
}
</style>
