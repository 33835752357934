<template>
  <div class='records-sidebar'>
    
    <div class='filter-controls'>
      <b-button class='refresh' @click='load_page( current_page )' variant='link'>
        <font-awesome-icon icon="fa-regular fa-arrows-rotate" />
        <span>Refresh</span>
      </b-button>
    </div>

    <div class='results'>
      <jj-loading v-if='loading_records' centered />
      <template v-else>
        <ul id='records-list'>
          <li v-for='record in records' :key='record.id' is='InventoryRecordSidebarSingle' :record='record'/>
        </ul>
        <div v-if='total_rows' class='pagination-wrap'>
          <div>Showing {{ current_page_min }}-{{ current_page_max }} of {{ total_rows }}</div>
          <b-pagination
            :value="current_page"
            @input='load_page'
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="records-list"
            hide-goto-end-buttons
            limit=5
            hide-ellipsis
            />
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import axios from '@/axios.js';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapMutations, mapActions } = createNamespacedHelpers( 'orders' );
import InventoryRecordSidebarSingle from './single';

import EventBus from '@/event-bus';

export default {
  name: 'InventoryRecordSidebar',
  components: {
    InventoryRecordSidebarSingle,
  },
  data () {
    return {
      abort_controller: null,
      records: [],
      per_page: 10,
      current_page: 1,
      total_rows: null,
      max_num_pages: null,
      loading_records: false,
      creating_new_records: false,
    }
  },
  computed: {
    current_page_min () {
      return ( this.current_page - 1 ) * this.per_page + 1;
    },
    current_page_max () {
      return ( this.current_page - 1 ) * this.per_page + this.per_page > this.total_rows ? this.total_rows : ( this.current_page - 1 ) * this.per_page + this.per_page;
    },
  },
  methods: {

    load_page: _.debounce( function ( page ) {
      this.load_records ({ page });
    }, 250 ),

    load_records ( args = {} ) {
      if ( this.abort_controller ) {
        this.abort_controller.abort();
      }
      this.abort_controller = new AbortController();
      this.loading_records = true;
      axios
        .get( '/inventory/records', {
          signal: this.abort_controller.signal,
          params: Object.assign( args, {
            per_page: this.per_page,
          }),
        })
        .then( response => {
          this.records = response.data.records;
          this.total_rows = response.data.total_rows;
          this.current_page = response.data.page;
          this.max_num_pages = response.data.max_num_pages;
          this.loading_records = false;
        })
        .catch( e => {
          this.records = [];
          this.total_rows = 0;
          if ( _.get( e, 'constructor.name' ) != 'Cancel' ) {
            this.loading_records = false;
          }
        })
    },

    receive_record_deleted ( id ) {
      this.$jjToast({
        type: 'success',
        title: 'Deleted',
        content: 'Deleted Record',
      });
      if ( this.records.some( x => x.id == id ) ) {
        this.load_records();
      }
    },
    
    receive_record_created ( id ) {
      this.load_records();
    },

  },
  created () {
    this.load_records();
    EventBus.$on( 'inventory-report-deleted', this.receive_record_deleted );
    EventBus.$on( 'inventory-report-created', this.receive_record_created );
    // this.load_records();
  },
  beforeDestroy () {
    EventBus.$off( 'inventory-report-deleted', this.receive_record_deleted );
    EventBus.$off( 'inventory-report-created', this.receive_record_created );
  },
}
</script>


<style lang="scss">
.records-sidebar {
  border-right: 1px solid var( --bs-border-color );
  .filter-controls {
    padding: 1.25rem;
    > .row {
      --gutter-y: 2rem;
    }
    input,
    select,
    button {
      display: block;
      width: 100%;
    }
  }
  .results {
    background-color: var( --medium-light );
    border-top: 1px solid var( --bs-border-color );
    position: relative;
  }
  #records-list {
    list-style: none;
    margin: 0;
    padding: 0.25rem;
    li:not(:first-child) {
      margin-top: 0.25rem;
    }
  }
  .pagination-wrap {
    padding: 0.75rem;
    text-align: center;
    border-top: 1px solid var( --bs-border-color );
    background-color: white;
    font-size: 0.9em;
  }
  .pagination {
    justify-content: center;
    margin: 0.5rem 0 0;
  }
  .multiselect {
    margin-top: 0.75rem;
  }
  .refresh {
    padding: 0;
    margin: 0.75em auto 0;
    width: auto !important;
  }
}
</style>